
<template>
  <v-container class="pa-6" fluid>
    <v-row>
      <v-col cols="12" sm="12" md="5">
        <v-row>
          <v-col cols="12" sm="12">
            <v-card v-if="!weekend" class="card-bottom py-6">
              <h4 class="font-weight-light text-center">NENHUM FINAL DE SEMANA DISPONIVEL NO MOMENTO...</h4>
            </v-card>
            <v-card v-else class="card-bottom py-6" justify="center" align="center" elevation="10">
              <div class="pa-4">
                <h4 class="font-weight-light text-center">HÁ UM FINAL DE SEMANA ABERTO, VOCÊ ESTÁ DISPONIVEL?</h4>
                <h4 class="font-weight-light text-center"><strong>{{ weekend.name }}</strong></h4>
                <v-btn class="mt-4" outlined @click="currentWeek = weekend; dialogs.weekend = true">SIM, ESTOU DISPONIVEL!</v-btn>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12">
            <v-card class="card-bottom" elevation="10">
              <v-card-title>SUAS DISPONIBILIDADES</v-card-title>
              <v-divider />
              <div class="pa-4">
                <v-data-table :headers="tables.myWeekends" :items="myWeekends">
                  <template v-slot:[`item.visibility`]="{ item }">
                    <v-chip v-if="item.visibility" color="success" small>ABERTO</v-chip>
                    <v-chip v-else color="error" small>FECHADO</v-chip>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="7">
        <v-row>
          <v-col cols="12" sm="12">
            <v-card class="card-bottom" elevation="10">
              <v-card-title>LISTAGEM DE AULAS</v-card-title>
              <v-divider />
              <div class="pa-4">
                <v-data-table :headers="tables.myClasses" :items="myClasses">
                  <template v-slot:[`item.code_unit`]="{ item }">
                    <div>{{ item.code }}</div>
                    <div>{{ item.unit }}</div>
                  </template>
                  <template v-slot:[`item.teacher_support`]="{ item }">
                    <div>- {{ item.teacher.name || '******' }}</div>
                    <div>- {{ item.support.name || '******' }}</div>
                  </template>
                  <template v-slot:[`item.course_discipline`]="{ item }">
                    <div>- {{ item.course }}</div>
                    <div>- {{ item.discipline || '******' }}</div>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon @click="$router.push({ name: 'class', params: { weekend_id: item.weekend, class_id: item.id } })">fa-solid fa-folder-open</v-icon>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogs.weekend" width="500" persistent>
      <v-card class="pa-3">
        <v-card-title>CONFIRMAÇÃO</v-card-title>
        <v-divider />
        <div class="pa-4">
          <p class="ma-0">Você deseja realmente confirmar sua disponibilidade para o final de semana <strong>{{ currentWeek.name }}</strong>?</p>
        </div>
        <v-divider />
        <v-row class="pa-4">
          <v-col cols="12" sm="12" md="6">
            <v-btn color="success" outlined block @click="confirmSupport()">SIM, CONTINUAR</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn color="warning" outlined block @click="dialogs.weekend = false">NÃO, CANCELAR</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from '../plugins/firebase.js'
import { collection, query, where, doc, orderBy, limit, onSnapshot, updateDoc, arrayUnion, collectionGroup } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'

const db = firebase.db
const auth = firebase.auth

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      weekend: null,
      myWeekends: [],
      myClasses: [],
      currentWeek: {},
      currentUser: {},
      dialogs: {
        weekend: false
      },
      tables: {
        myWeekends: [
          {
            text: 'DESCRIÇÃO',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'STATUS',
            value: 'visibility',
            sortable: false,
          },
        ],
        myClasses: [
          {
            text: 'TURMA / UNIDADE',
            align: 'start',
            sortable: false,
            value: 'code_unit',
          },
          {
            text: 'CURSO / DISCIPLINA',
            align: 'start',
            sortable: false,
            value: 'course_discipline',
          },
          {
            text: 'PROFESSOR / APOIO',
            align: 'start',
            sortable: false,
            value: 'teacher_support',
          },
          {
            text: 'AÇÕES',
            align: 'start',
            sortable: false,
            value: 'actions',
          },
        ]
      }
    }
  },
  async created() {

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.currentUser = user.uid

        const weekQuery = await query(collection(db, 'weekends'), where('visibility', '==', true), limit(1))
        onSnapshot(weekQuery, weekSnapshot => {
          this.weekend = null
          weekSnapshot.forEach((week) => {
            if(!week.data().supports.includes(user.uid)) {
              this.weekend = week.data()
              this.weekend.id = week.id
            }            
          })
        })

        const myWeekQuery = await query(collection(db, 'weekends'), where('supports', 'array-contains', user.uid), orderBy('timestamp', 'desc'))
        onSnapshot(myWeekQuery, myWeekSnapshot => {
          this.myWeekends = []
          myWeekSnapshot.forEach(week => {
            this.myWeekends.push({ ...week.data(), id: week.id })
          })
        })

        onSnapshot(query(collectionGroup(db, 'classes'), where('support.id', '==', user.uid), orderBy('timestamp')), async (classes) => {
          this.myClasses = []
          classes.forEach(classs => {
            this.myClasses.push({ ...classs.data(), id: classs.id })
          })
        })
      }
    })

  },
  methods: {
    async confirmSupport() {
      const weekRef = doc(db, 'weekends', this.currentWeek.id)
      await updateDoc(weekRef, {
        supports: arrayUnion(auth.currentUser.uid)
      })
      this.dialogs.weekend = false
    }
  }
}
</script>

<style scoped>
  .card-bottom {
    border-bottom: solid 6px #942420 !important;
  }
</style>
