<template>
  <v-app>
    <v-main class="main-box">
      <v-app-bar v-if="this.$route.name != 'access'" color="#a11217">
        <v-icon class="ml-4 white--text" @click="$router.push({ name: 'home' })">fas fa-home</v-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title class="white--text font-weight-medium">IPOG - PLATAFORMA DE APOIOS</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon class="mr-4 white--text" @click="logout()">fas fa-sign-out-alt</v-icon>
        <!-- <v-btn @click="logout()">SAIR</v-btn> -->
      </v-app-bar>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import firebase from './plugins/firebase.js'
const db = firebase.db
const auth = firebase.auth

import { onAuthStateChanged, signOut } from 'firebase/auth'

export default {
  name: 'App',
  data: () => ({
    //
  }),
  methods: {
    async logout() {
      signOut(auth).then(() => {
        this.$router.push({ name: 'access' })
      })
    }
  }
}
</script>

<style>
  .main-box {
    background-color: #f6f7f9;
  }
</style>
