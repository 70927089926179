
<template>
  <v-container class="pa-6" fluid>
    <v-row>
      <v-col cols="12" sm="12" md="5" lg="3">
        <v-row>
          <v-col cols="12" sm="12">
            <v-card>
              <v-card-title>APOIOS DISPONIVEIS</v-card-title>
              <v-divider />
              <div class="pa-4">
                <v-data-table :headers="tables.supports" :items="supports" :items-per-page="-1"/>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="7" lg="9">
        <v-row>
          <v-col cols="12" sm="12">
            <v-card>
              <v-card-title>TURMAS {{ weekend.name }}</v-card-title>
              <v-divider />
              <div class="pa-4">
                <v-data-table :headers="tables.classes" :items="classes" :items-per-page="-1">
                  <template v-slot:[`item.code_unit`]="{ item }">
                    <div>{{ item.code }}</div>
                    <div>{{ item.unit }}</div>
                  </template>
                  <template v-slot:[`item.teacher_support`]="{ item }">
                    <div class="mt-1"><v-chip class="my-1" :color="item.aligned.state ? 'success': 'error'" small>{{ item.teacher.name || 'INDEFINIDO' }}</v-chip></div>
                    <div class="mb-1"><v-chip class="my-1" :color="item.confirmed.state ? 'success': 'error'" small>{{ item.support.name || 'INDEFINIDO' }}</v-chip></div>
                  </template>
                  <template v-slot:[`item.course_discipline`]="{ item }">
                    <div>- {{ item.course }}</div>
                    <div>- {{ item.discipline || 'INDEFINIDO' }}</div>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon class="ma-1" @click="setCurrentClass(item)">fa-solid fa-eye</v-icon>
                    <v-icon class="ma-1" @click="$router.push({ name: 'class', params: { weekend_id: item.weekend, class_id: item.id } })">fa-solid fa-folder-open</v-icon>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12">
            <v-card>
              <v-row class="pa-4">
                <v-col cols="12" sm="12" md="4">
                  <v-btn color="success" @click="dialogs.createClass = true" outlined block>ADICIONAR TURMA</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogs.classDetails" width="600" persistent>
      <v-card>
        <v-card-title>TURMA {{ currentClass.code }}</v-card-title>
        <v-card-subtitle>{{ currentClass.id }}</v-card-subtitle>
        <v-divider />
        <div class="pa-4">
          <p>CURSO: <strong>{{ currentClass.course }}</strong></p>
          <p>DISCIPLINA: <strong>{{ currentClass.discipline }}</strong>.</p>
          <p>MODULO: <strong>{{ currentClass.module }}</strong></p>
          <p>ABERTURA: <strong>{{ currentClass.inauguration ? 'SIM': 'NÃO' }}</strong> - ENCERRAMENTO: <strong>{{ currentClass.closing ? 'SIM': 'NÃO' }}</strong> - GRAVADA: <strong>{{ currentClass.recorded ? 'SIM': 'NÃO' }}</strong></p>
          <v-divider class="mb-4"/>
          <p>PROFESSOR: <strong>{{ currentClass.teacher.name }}</strong>.</p>
          <p>TELEFONE: <strong>{{ currentClass.teacher.telephone }}</strong> - CELULAR: <strong>{{ currentClass.teacher.cellphone }}</strong></p>
          <p>E-MAIL: <strong>{{ currentClass.teacher.mail }}</strong></p>
          <v-divider class="mb-4"/>
          <p>APOIO: <strong>{{ currentClass.support.name || 'INDEFINIDO' }}</strong> <v-icon style="margin-top: -8px" @click="dialogs.supportChange = true">fas fa-edit</v-icon></p>
          <p>IDENTIFICADOR: <strong>{{ currentClass.support.id || 'INDEFINIDO' }}</strong></p>
          <v-divider class="mb-4"/>
          <p>DADOS ZOOM: <strong>{{ currentClass.zoom.id }}</strong></p>
          <p>URL ZOOM: <strong>{{ currentClass.zoom.url }}</strong></p>
          <p>LICENÇA: <strong>{{ currentClass.zoom.mail }}</strong></p>
          <p>SENHA: <strong>{{ currentClass.zoom.password }}</strong></p>
          <p>WHATSAPP: <strong>{{ currentClass.whatsapp || 'INDEFINIDO' }}</strong></p>
          <p>AVALIAÇÃO: <strong>https://ipog.onva.in/q/{{ currentClass.code }}</strong></p>
          <v-divider class="mb-4"/>
          <p>CONFIRMAÇÃO DE RECEBIMENTO: <strong>{{ currentClass.confirmed.state ? 'SIM' : 'NÃO' }}</strong></p>
          <p>CONFIRMAÇÃO DE ALINHAMENTO: <strong>{{ currentClass.aligned.state ? 'SIM' : 'NÃO' }}</strong></p>
          <v-divider class="mb-4"/>
          <v-btn color="warning" @click="dialogs.classDetails = false">FECHAR</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.supportChange" width="400" persistent>
      <v-card>
        <v-card-title>ALTERAR APOIO</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-row>
            <v-col cols="12" sm="12">
              <v-select v-model="currentSupport" :items="supports" item-text="name" item-value="user" return-object label="APOIOS" @change="updateSupport()" hide-details outlined/>
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <v-btn color="warning" @click="dialogs.supportChange = false">CANCELAR</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.createClass" width="600" persistent>
      <v-card>
        <v-card-title>CADASTRO DE TURMA</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-form>
            <v-row class="pa-4">
              <v-col cols="12" sm="12" class="pa-0">
                <v-text-field v-model="forms.addClass.code" label="CÓDIGO" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="pa-0">
                <v-text-field v-model="forms.addClass.course" label="CURSO" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="pa-0">
                <v-text-field v-model="forms.addClass.discipline" label="DISCIPLINA" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="pa-0">
                <v-text-field v-model="forms.addClass.module" label="MODULO" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="pa-0">
                <v-text-field v-model="forms.addClass.id" label="ZOOM ID" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="pa-0">
                <v-text-field v-model="forms.addClass.pass" label="ZOOM SENHA" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="pa-0">
                <v-text-field v-model="forms.addClass.license" label="LICENÇA" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="pa-0">
                <v-text-field v-model="forms.addClass.password" label="SENHA" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="pa-0 mb-4">
                <v-divider />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-btn @click="createClass()" block outlined>SALVAR</v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-btn @click="dialogs.createClass = false" block outlined>FECHAR</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from '../plugins/firebase.js'
import { onSnapshot, doc, addDoc, where, limit, query, collection, getDocs, updateDoc, serverTimestamp, orderBy } from 'firebase/firestore'
import async from 'async'

const db = firebase.db

export default {
  name: 'Weekend',
  props: [ 'weekend_id' ],
  data() {
    return {
      weekend: null,
      supports: [],
      classes: [],
      currentClass: {
        teacher: {},
        support: {},
        zoom: {},
        confirmed: {},
        aligned: {},
      },
      currentSupport: {},
      tables: {
        supports: [
          {
            text: 'NOME',
            align: 'start',
            sortable: true,
            value: 'name',
          },
        ],
        classes: [
          {
            text: 'TURMA / UNIDADE',
            align: 'start',
            sortable: false,
            value: 'code_unit',
          },
          {
            text: 'CURSO / DISCIPLINA',
            align: 'start',
            sortable: false,
            value: 'course_discipline',
          },
          {
            text: 'PROFESSOR / APOIO',
            align: 'start',
            sortable: false,
            value: 'teacher_support',
          },
          {
            text: 'AÇÕES',
            align: 'start',
            sortable: false,
            value: 'actions',
          },
        ]
      },
      dialogs: {
        classDetails: false,
        supportChange: false,
        createClass: false,
      },
      forms: {
        addClass: {
          code: '',
          course: '',
          discipline: '',
          module: '',
          unit: 'IPOG Brasil',
          id: '',
          pass: '',
          license: '',
          password: ''
        }
      },
    }
  },
  created() {

    onSnapshot(doc(db, 'weekends', this.weekend_id), (weekend) => {
      this.weekend = weekend.data()
      this.supports = []
      async.eachLimit(this.weekend.supports, 1, async (supportId, callback) => {
        const supportQuery = query(collection(db, 'users'), where('user','==',supportId), limit(1))
        const supportSnapshot = await getDocs(supportQuery)
        supportSnapshot.forEach(support => {
          this.supports.push(support.data())
        })
      })
    })

    onSnapshot(query(collection(db, `weekends/${this.weekend_id}/classes`), orderBy('timestamp')), classesSnapshot => {
      this.classes = []
      classesSnapshot.forEach(classs => {
        this.classes.push({ ...classs.data(), id: classs.id })
      })
    })

  },
  methods: {
    async setCurrentClass(classs) {
      this.currentClass = classs
      this.currentSupport = {
        name: classs.support.name,
        user: classs.support.id
      }
      this.dialogs.classDetails = true
    },
    async updateSupport() {

      await updateDoc(doc(db, `weekends/${this.weekend_id}/classes`, this.currentClass.id), {
        support: {
          name: this.currentSupport.name,
          id: this.currentSupport.user
        }
      })
      this.currentClass.support = {
        name: this.currentSupport.name,
        id: this.currentSupport.user
      }
      this.dialogs.supportChange = false

    },
    async createClass() {

      await addDoc(collection(db, `weekends/${this.weekend_id}/classes`), {
        code: this.forms.addClass.code,
        course: this.forms.addClass.course,
        discipline: this.forms.addClass.discipline,
        module: this.forms.addClass.module,
        unit: this.forms.addClass.unit,
        aligned: {
          state: false,
          timestamp: serverTimestamp()
        },
        confirmed: {
          state: false,
          timestamp: serverTimestamp()
        },
        support: {
          name: '',
          id: ''
        },
        ratings: {
          students: 0,
        },
        inauguration: false,
        closing: false,
        recorded: true,
        teacher: {
          name: '',
          telephone: '',
          cellphone: '',
          mail: ''
        },
        zoom: {
          code: this.forms.addClass.id,
          pass: this.forms.addClass.pass,
          mail: this.forms.addClass.license,
          password: this.forms.addClass.password
        },
        weekend: this.weekend_id,
        whatsapp: '',
        timestamp: serverTimestamp()
      })

      this.dialogs.createClass = false

    }
  }
}
</script>

<style scoped>

</style>
