
import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/pt-br'

Vue.filter('roomStatusName', (payload) => {
  if (payload == 'meeting.started') {
    return `SALA ABERTA`
  } else {
    return `SALA FECHADA`
  }
})

Vue.filter('recordingStatusName', (payload) => {
  if (payload == 'recording.started' || payload == 'recording.resumed') {
    return `GRAVANDO`
  } else if (payload == 'recording.paused') {
    return `GRAVAÇÃO PAUSADA`
  } else if (payload == 'recording.stopped') {
    return `GRAVAÇÃO ENCERRADA`
  }
})

Vue.filter('codeURL', (payload) => {
  return encodeURI(payload)
})
