
<template>
  <v-container class="pa-6" fluid>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-card>
          <v-card-title>DETALHES DA TURMA</v-card-title>
          <v-card-subtitle>{{ class_id }}</v-card-subtitle>
          <v-divider />
          <v-row class="pa-4">
            <v-col cols="12">
              <p>CÓDIGO: <strong>{{ classs.code }}</strong></p>
              <p>CURSO: <strong>{{ classs.course }}</strong></p>
              <p>DISCIPLINA: <strong>{{ classs.discipline }}</strong></p>
              <p>MODULO: <strong>{{ classs.module }}</strong> - UNIDADE: <strong>{{ classs.unit }}</strong></p>
              <p>INAUGURAÇÃO: <strong>{{ classs.inauguration ? 'SIM' : 'NÃO' }}</strong> - ENCERRAMENTO: <strong>{{ classs.closing ? 'SIM' : 'NÃO' }}</strong> - GRAVADA: <strong>{{ classs.recorded ? 'SIM' : 'NÃO' }}</strong></p>
              <v-divider class="mb-4"/>
              <p>PROFESSOR: <strong>{{ classs.teacher.name }}</strong></p>
              <p>TELEFONE: <strong>{{ classs.teacher.telephone }}</strong> - CELULAR: <strong>{{ classs.teacher.cellphone }}</strong></p>
              <p>E-MAIL: <strong>{{ classs.teacher.mail }}</strong></p>
              <v-divider class="mb-4"/>
              <p>DADOS ZOOM: <strong>{{ classs.zoom.id }}</strong></p>
              <p>URL ZOOM: <strong>{{ classs.zoom.url || '******' }}</strong></p>
              <p>LICENÇA: <strong>{{ classs.zoom.mail }}</strong> - SENHA: <strong>{{ classs.zoom.password }}</strong></p>
              <v-divider class="mb-4"/>
              <p>WHATSAPP: <strong>{{ classs.whatsapp || '******' }}</strong></p>
              <p>AVALIAÇÃO ALUNOS: <strong>https://ipog.onva.in/q/{{ classs.code }}</strong></p>
              <p>AVALIAÇÃO PROFESSOR(A): <strong>{{ `https://ipog.onva.in/q/${classs.teacher.name}` | codeURL }}</strong></p>
              <v-divider class="mb-4"/>
              <p>NOTA PELOS ALUNOS: <strong>{{ classs.ratings.students || 'PENDENTE' }}</strong></p>
              <v-divider class="mb-4"/>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="4">
                  <v-btn :disabled="classs.confirmed.state" @click="setConfirmed()" block outlined>CONFIRMAR AULA</v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4">
                  <v-btn :disabled="!classs.confirmed.state || classs.aligned.state" @click="dialogs.confirmAlign = true" block outlined>CONFIRMAR ALINHAMENTO</v-btn>
                </v-col>
              </v-row>
              <div v-if="classs.confirmed.state" class="mt-4">
                <h5 class="ma-0">AULA CONFIRMADA EM {{ classs.confirmed.timestamp }}</h5>
                <h5 v-if="classs.aligned.state" class="ma-0">ALINHAMENTO CONFIRMADO EM {{ classs.aligned.timestamp }}</h5>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row>
          <v-col cols="12" sm="12">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-card :color="roomColor" class="py-7">
                  <h3 v-if="roomStatus[roomStatus.length-1]" class="text-center white--text">{{ roomStatus[roomStatus.length-1].event | roomStatusName }}</h3>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="6">
               <v-card :color="recordingColor" class="py-7">
                  <h3 v-if="recordingStatus[recordingStatus.length-1]" class="text-center white--text">{{ recordingStatus[recordingStatus.length-1].event | recordingStatusName }}</h3>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12">
            <v-card>
              <v-data-table :headers="tables.students" :items="participants" />
            </v-card>
          </v-col>
          <v-col cols="12" sm="12">
            <v-row>
              <v-col cols="12" sm="12">
                <v-card class="pa-4">
                  <v-switch v-model="soundOn" class="mb-5 ml-2" label="CAMPAINHA DE ACESSO A AULA" hide-details inset color="success" />
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogs.confirmAlign" width="400" persistent>
      <v-card>
        <v-card-title>CONFIRMAÇÃO DE ALINHAMENTO</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-row>
            <v-col cols="12" sm="12">
              <p>Você confirma que realizou o alinhamento com o professor(a)?</p>
              <v-divider />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-btn color="warning" @click="dialogs.confirmAlign = false" block outlined>NÃO</v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-btn color="success" @click="setAligned()" block outlined>CONFIRMAR</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from '../plugins/firebase.js'
import { onSnapshot, query, limit, where, updateDoc, doc, serverTimestamp, collection, orderBy } from 'firebase/firestore'
import moment from 'moment'
import * as Tone from 'tone'

const vol = new Tone.Volume(16).toDestination()
const sound = new Tone.Oscillator(440, 'sine').connect(vol).toDestination()

const db = firebase.db

export default {
  name: 'class',
  props: [ 'weekend_id', 'class_id' ],
  data() {
    return {
      classs: {
        teacher: {},
        support: {},
        zoom: {},
        confirmed: {},
        aligned: {},
        ratings: {},
      },
      participants: [],
      roomStatus: [],
      recordingStatus: [],
      tables: {
        students: [
          {
            text: 'NOME',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'DATA HORA',
            value: 'timestamp',
            sortable: false,
          },
          {
            text: 'CÓDIGO',
            value: 'id',
            sortable: false,
          },
        ]
      },
      dialogs: {
        confirmAlign: false,
      },
      soundOn: true,
      shotting: 0,
      recordingColor: 'black',
      roomColor: 'black',
    }
  },
  async created() {

    onSnapshot(doc(db, `weekends/${this.weekend_id}/classes/${this.class_id}`), async (classs) => {
      this.classs = classs.data()
      this.classs.confirmed.timestamp = moment(this.classs.confirmed.timestamp.toDate()).format('DD/MM/YYYY HH:mm:ss')
      this.classs.aligned.timestamp = moment(this.classs.aligned.timestamp.toDate()).format('DD/MM/YYYY HH:mm:ss')

      onSnapshot(query(collection(db, 'participantJoined'), where('id', '==', this.classs.zoom.code), orderBy('timestamp', 'desc')), async (participantSnapshot) => {
        this.participants = []
        this.shotting += 1
        participantSnapshot.forEach(async (participant) => {
          this.participants.push({
            name: participant.data().participant.user_name,
            host: participant.data().host_id,
            zoom_id: participant.data().id,
            id: participant.id,
            timestamp: moment(participant.data().timestamp.toDate()).format('DD/MM/YYYY HH:mm:ss')
          })
        })

        if (this.shotting > 1 && this.soundOn) {
          sound.start()
          setTimeout(() => {
            sound.stop()
          }, 500)
        }
        
      })

      onSnapshot(query(collection(db, 'roomStatus'), where('id', '==', this.classs.zoom.code), orderBy('timestamp', 'desc')), async (roomSnapshot) => {
        roomSnapshot.forEach(status => {
          this.roomStatus.unshift({ ...status.data(), id: status.id })
          this.setColorRoomStatus()
        })
      })

      onSnapshot(query(collection(db, 'recordingStatus'), where('id', '==', Number(this.classs.zoom.code)), orderBy('timestamp', 'desc')), async (recordingSnapshot) => {
        recordingSnapshot.forEach(status => {
          this.recordingStatus.unshift({ ...status.data(), id: status.id })
          this.setColorRecordingStatus()
        })
      })
      
    })

  },
  methods: {
    async setConfirmed() {
      await updateDoc(doc(db, `weekends/${this.classs.weekend}/classes`, this.class_id), {
        confirmed: {
          state: true,
          timestamp: serverTimestamp()
        }
      })
    },
    async setAligned() {
      await updateDoc(doc(db, `weekends/${this.classs.weekend}/classes`, this.class_id), {
        aligned: {
          state: true,
          timestamp: serverTimestamp()
        }
      })
      this.dialogs.confirmAlign = false
    },
    async setColorRecordingStatus() {
      const payload = this.recordingStatus[this.recordingStatus.length-1].event
      if (payload == 'recording.started' || payload == 'recording.resumed') {
        this.recordingColor = 'success'
      } else if (payload == 'recording.paused') {
        this.recordingColor = 'warning'
      } else if (payload == 'recording.stopped') {
        this.recordingColor = '#a11217'
      }
    },
    async setColorRoomStatus() {
      const payload = this.roomStatus[this.roomStatus.length-1].event
      if (payload == 'meeting.started') {
        this.roomColor = 'success'
      } else {
        this.roomColor = 'black'
      }
    }
  }
}
</script>

<style scoped>

</style>
