
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getAuth } from "firebase/auth"
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCvzVjNXZD262ykynoFA3oLmVYxLkfw17Y",
  authDomain: "ipog-apoios.firebaseapp.com",
  projectId: "ipog-apoios",
  storageBucket: "ipog-apoios.appspot.com",
  messagingSenderId: "440741257281",
  appId: "1:440741257281:web:c12da5d5accac6916466c0",
  measurementId: "G-FQ8CL9M60X"
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
const db = getFirestore(app)

export default { app, auth, db }
