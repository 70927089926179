
<template>
  <v-container class="pa-6" fluid>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-card class="card-bottom" elevation="10">
          <v-card-title>SEMANAS DE AULA</v-card-title>
          <v-divider />
          <div class="pa-4">
            <v-data-table :headers="tables.weekends.header" :items="weekends">
              <template v-slot:[`item.visibility`]="{ item }">
                <v-chip v-if="item.visibility" color="success" small>DISPONIVEL</v-chip>
                <v-chip v-else color="error" small>INDISPONIVEL</v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon @click="$router.push({ name: 'weekend', params: { weekend_id: item.id } })">fa-solid fa-folder-open</v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-card  class="card-bottom" elevation="10">
          <v-card-title>EQUIPE DE APOIOS</v-card-title>
          <v-divider />
          <div class="pa-4">

          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '../plugins/firebase.js'
const db = firebase.db

import { onSnapshot, query, collection } from 'firebase/firestore'

export default {
  name: 'Panel',
  data() {
    return {
      weekends: [],
      tables: {
        weekends: {
          header: [
            {
              text: 'DESCRIÇÃO',
              align: 'start',
              sortable: true,
              value: 'name',
            },
            {
              text: 'VISIBILIDADE',
              value: 'visibility',
              sortable: false,
            },
            {
              text: 'AÇÕES',
              value: 'actions',
              sortable: false,
            }
          ]
        }
      }
    }
  },
  created() {
    const weekendsQuery = query(collection(db, 'weekends'))
    onSnapshot(weekendsQuery, (weekendsSnapshot) => {
      this.weekends = []
      weekendsSnapshot.forEach(week => {
        this.weekends.push({ ...week.data(), id: week.id, actions: '' })
      })
    })
  },
}
</script>

<style scoped>
  .card-bottom {
    border-bottom: solid 6px #942420 !important;
  }
</style>
