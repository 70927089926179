
<template>
  <v-container class="pa-0" fluid :style="{'background': 'url(' + require('@/assets/accessBG.jpg') + ') center'}">
    <v-row class="login-box" align="center" justify="center">
      <v-col cols="12" xs="10" sm="8" md="5" lg="3">
        <v-card v-show="!isRegistry" class="pt-4">
          <v-form v-model="login.valid" ref="loginForm" class="pa-4">
            <v-row class="pb-4">
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="login.mail" label="E-MAIL" prepend-inner-icon="fa-solid fa-envelope" :rules="rules.mail" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="login.password" label="SENHA" type="password" prepend-inner-icon="fa-solid fa-lock" :rules="rules.password" @keypress.enter="sendToLogin()" outlined/>
                <v-divider />
              </v-col>
              <v-col cols="12" sm="12" md="6" class="py-0 mt-6">
                <v-btn block outlined @click="sendToLogin()">ENTRAR</v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="py-0 mt-6">
                <v-btn block outlined @click="isRegistry = true">CADASTRE-SE</v-btn>
              </v-col>
              <v-col v-show="errors.login.show" cols="12" sm="12" class="py-0 mt-6">
                <v-alert v-model="errors.login.show" class="white--text" type="error" border="left" dismissible>{{ errors.login.message }}</v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-card v-show="isRegistry" class="pt-4">
          <v-form v-if="registryStep == 1" v-model="registryStep1.valid" ref="registry1Form" class="pa-4">
            <v-row class="pb-4">
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="registryStep1.name" label="NOME" :rules="rules.name" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="registryStep1.mail" label="E-MAIL" :rules="rules.mail" outlined/>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="py-0">
                <v-text-field v-model="registryStep1.telephone" label="TELEFONE (XX XXX XXX XXX)" v-mask="'## ### ### ###'" :rules="rules.telephone" outlined/>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="py-0">
                <v-text-field v-model="registryStep1.document" label="DOCUMENTO (CPF)" :rules="rules.document" v-mask="'###.###.###-##'" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="registryStep1.birth" label="DATA DE NASCIMENTO" :rules="rules.birth" v-mask="'##/##/####'" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="registryStep1.password" label="SENHA" type="password" :rules="rules.password" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="registryStep1.repassword" label="CONFIRME A SENHA" type="password" :rules="rules.repassword" outlined/>
                <v-divider />
              </v-col>
              <v-col cols="12" sm="12" class="py-0 mt-6">
                <v-btn @click="registryToStep1()" block outlined>CONFIRMAR E CONTINUAR</v-btn>
              </v-col>
              <v-col v-show="errors.registry1.show" cols="12" sm="12" class="py-0 mt-6">
                <v-alert v-model="errors.registry1.show" class="white--text" type="error" border="left" dismissible>{{ errors.registry1.message }}</v-alert>
              </v-col>
            </v-row>
          </v-form>
          <v-form v-show="registryStep == 2" v-model="registryStep2.valid" ref="registry2Form" class="pa-4">
            <v-row class="pb-4">
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="registryStep2.zipcode" label="CÓDIGO POSTAL (CEP)" :rules="rules.zip" v-mask="'#####-###'" @keyup="verifyZip()" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="registryStep2.address" label="LOGRADOURO" :rules="rules.address" disabled outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="registryStep2.number" label="NÚMERO" :rules="rules.number" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="registryStep2.complement" label="COMPLEMENTO" outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="registryStep2.district" label="BAIRRO" :rules="rules.district" disabled outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="registryStep2.city" label="CIDADE" :rules="rules.city" disabled outlined/>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field v-model="registryStep2.state" label="ESTADO" :rules="rules.state" disabled outlined/>
                <v-divider />
              </v-col>
              <v-col cols="12" sm="12" class="py-0 mt-6">
                <v-btn @click="registryToStep2()" block outlined>CONFIRMAR E CADASTRAR</v-btn>
              </v-col>
              <v-col v-show="errors.registry2.show" cols="12" sm="12" class="py-0 mt-6">
                <v-alert v-model="errors.registry2.show" class="white--text" type="error" border="left" dismissible>{{ errors.registry2.message }}</v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { cpf } from 'cpf-cnpj-validator'
import cep from 'cep-promise'
import firebase from '../plugins/firebase.js'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'

const auth = firebase.auth
const db = firebase.db

export default {
  name: 'Access',
  data() {
    return {
      login: {
        mail: '',
        password: '',
        valid: true
      },
      registryStep1: {
        name: '',
        mail: '',
        document: '',
        birth: '',
        telephone: '',
        password: '',
        repassword: '',
        valid: true,
      },
      registryStep2: {
        zipcode: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
        valid: true,
      },
      rules: {
        name: [
          v => !!v || 'Nome obrigatório.',
          v => v.length >= 6 || 'Minimo de 6 caracteres.',
        ],
        mail: [
          v => !!v || 'E-mail obrigatório.',
          v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail invalido.'
        ],
        telephone: [
          v => !!v || 'Telefone obrigatório.',
          v => v.length >= 13 || 'Telefone invalido.',
        ],
        document: [
          v => !!v || 'CPF obrigatório.',
          v => v.length == 14 || 'CPF incompleto',
          v => cpf.isValid(v) || 'CPF invalido.'
        ],
        birth: [
          v => !!v || 'Nascimento obrigatório.',
          v => v.length == 10 || 'Nascimento invalido'
        ],
        password: [
          v => !!v || 'Senha obrigatório.',
          v => v.length >= 8 || 'Senha deve possuir no minimo 8 digitos'
        ],
        repassword: [
          v => !!v || 'Confirmação de senha obrigatória.',
          v => v == this.registryStep1.password || 'As senhas devem ser identicas.',
        ],
        zip: [
          v => !!v || 'CEP obrigatório.',
          v => v.length == 9 || 'CEP invalido.'
        ],
        address: [
          v => !!v || 'Logradouro obrigatório.',
        ],
        number: [
          v => !!v || 'Número obrigatório.',
        ],
        district: [
          v => !!v || 'Bairro obrigatório.',
        ],
        city: [
          v => !!v || 'Cidade obrigatória.',
        ],
        state: [
          v => !!v || 'Estado obrigatório.',
        ]
      },
      isRegistry: false,
      registryStep: 1,
      errors: {
        login: { show: false, message: '' },
        registry1: { show: false, message: '' },
        registry2: { show: false, message: '' },
      }
    }
  },
  methods: {
    async sendToLogin() {
      if (this.$refs.loginForm.validate()) {
        signInWithEmailAndPassword(auth, this.login.mail, this.login.password).then(userCredential => {
          this.$router.push({ name: 'home' })
        }).catch(err => {
          if (err.code == 'auth/wrong-password') {
            this.errors.login.message = 'Senha incorreta'
            this.errors.login.show = true
          } else if (err.code == 'auth/user-not-found') {
            this.errors.login.message = 'Usuário não encontrado'
            this.errors.login.show = true
          } else if (err.code == 'auth/too-many-requests') {
            this.errors.login.message = 'Excesso de tentativas de acesso'
            this.errors.login.show = true
          }
        })
      } else {
        this.errors.login.message = 'Verifique o preenchimento dos campos'
        this.errors.login.show = true
      }
    },
    async registryToStep1() {
      if (this.$refs.registry1Form.validate()) {
        this.registryStep = 2
      } else {
        this.errors.registry1.message = 'Verifique o preenchimento dos campos'
        this.errors.registry1.show = true
      }
    },
    async registryToStep2() {
      if (this.$refs.registry2Form.validate()) {
        createUserWithEmailAndPassword(auth, this.registryStep1.mail, this.registryStep1.password).then(async (userCredential) => {
          try {
            await addDoc(collection(db, `users`), {
              name: this.registryStep1.name.toUpperCase(),
              mail: this.registryStep1.mail.toLowerCase(),
              document: this.registryStep1.document,
              birth: this.registryStep1.birth,
              telephone: this.registryStep1.telephone,
              user: userCredential.user.uid,
              timestamp: serverTimestamp(),
              address: {
                base: this.registryStep2.address.toUpperCase(),
                number: this.registryStep2.number.toUpperCase(),
                complement: this.registryStep2.complement.toUpperCase(),
                district: this.registryStep2.district.toUpperCase(),
                city: this.registryStep2.city.toUpperCase(),
                state: this.registryStep2.state.toUpperCase(),
                zipcode: this.registryStep2.zipcode
              }
            })
            this.$router.push({ name: 'home' })
          } catch(err) {
            console.log(err)
          }
        })
      } else {
        this.errors.registry2.message = 'Verifique o preenchimento dos campos'
        this.errors.registry2.show = true
      }
    },
    async verifyZip() {
      let code = this.registryStep2.zipcode.replaceAll(`-`, ``)
      if (code.length == 8) {
        cep(code).then(address => {
          this.registryStep2.address = address.street,
          this.registryStep2.district = address.neighborhood,
          this.registryStep2.city = address.city,
          this.registryStep2.state = address.state
        }).catch(err => {
          this.errors.registry2.message = 'CEP não encontrado'
          this.errors.registry2.show = true
        })
      }
    }
  }
}
</script>

<style scoped>
  .login-box {
    height: 100vh;
  }
</style>
