
import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Access from '../views/Access.vue'
import Panel from '../views/Panel.vue'
import Weekend from '../views/Weekend.vue'
import Class from '../views/Class.vue'

import { onAuthStateChanged } from 'firebase/auth'
import firebase from '../plugins/firebase.js'
const auth = firebase.auth

onAuthStateChanged(auth, (user) => {
  if (user) {
    localStorage.setItem('logged', true)
  } else {
    localStorage.removeItem('logged')
  }
})

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/weekends/:weekend_id/classes/:class_id',
    name: 'class',
    component: Class,
    props: true
  },
  {
    path: '/access',
    name: 'access',
    component: Access
  },
  {
    path: '/panel',
    name: 'panel',
    component: Panel
  },
  {
    path: '/panel/weekends/:weekend_id',
    name: 'weekend',
    component: Weekend,
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const logged = localStorage.getItem('logged')

  document.title = 'IPOG APOIOS - PLATAFORMA DE GESTÃO'

  if (to.name != 'access' && !logged) {
    router.push({ name: 'access' })
  } else if (to.name == 'access' && logged) {
    router.push({ name: 'home' })
  }
  next()

})

export default router
